.scroll-table {
    height: 100%;
    overflow-y: auto;
    max-height: 100%;

    .table {
        position: relative;
        padding: 0;

        thead>tr>th {
            position: sticky;
            background-color: $white;
            top: 0;
            width: fit-content;
            border-top: none;
        }

        
    }
}

thead>tr>th{
    vertical-align: middle;
    border: 0;
    font-size: 1.1rem;
    font-weight: bolder;
    text-transform: capitalize;
}

tbody>tr>td{
    font-size: 1.1rem;
    vertical-align: middle;
}


th {
    fa-icon{
        display: none;
    }

    &.active {
        fa-icon {
          display: inline;
          margin-left: 0.5rem;
        }
    }
}


.custom-search{
    width: 100%;
    margin: 0;
    height: 4vh;
    font-size: 0.8rem;
  
    input{
        width: 100% !important;
        height: 100%;
        font-size: 0.8rem;
        background-color:$gray-500 ;
    }
}

