/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "_mixins.scss";
@import "_variables.scss";
@import "_typography.scss";
@import "_forms.scss";
@import "_sizes.scss";
@import "_tables.scss";
@import "_cards.scss";

@font-face {
    font-family: "Karla";
    src: url('/assets/fonts/Karla-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Karla-Bold";
    src: url('/assets/fonts/Karla-Bold.ttf') format('truetype');
}

html,body{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    font-family: "Karla";
    background-color: #eee !important;
    font-size: 16px;
}

app-root{
    height: 100%;
    width: 100%;
}

a:hover {
    text-decoration: none !important;
}

.row{
    margin: 0 !important;
}

a:hover{
    text-decoration: none;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
}

.pointer{
    cursor: pointer;
}

.btn{
    padding: 0.563rem 0.938rem;
}

.form-control:focus{
    outline: none !important;
    box-shadow: none;
}
.btn:focus{
    box-shadow: none;
}

.table {
    margin-bottom: 0;

    thead {
        th {
            vertical-align: middle !important;
            text-transform: capitalize;
        }
    }
}
//SIDEMENU CSS
.sidemenu-container{
    width: 15vw;
    background-color: $sidemenuBackground;
    color: $sidemenuText;
    height: 100%;
    transition: all 0.3s;
}

.sidemenu-container-collapsed{
    width: 5vw;
    background-color: $sidemenuBackground;
    color: $sidemenuText;
    height: 100%;
    transition: all 0.3s;
}

//VIEW CSS
.view-content-login{
    height: 100vh;
    width: 100vw;
}

.view-content{
    position: relative;
    height: $viewH;
    width: 100%;
    padding: 1.5rem;
    overflow-y: auto;
    overflow-x: hidden;
}

//ROUTE CONTENT CSS
.route-content{
    height: 100%;
    width: 100%;
}

.route-header{
    @include rowItemsVCenter;
    margin-bottom: 1rem;
}

.filter-select{
    width: 20vw !important;
    margin-left: auto;
}
//MODAL
.close{
    outline: none !important;
}

//PLACEHOLDER
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999;
    opacity: 1; /* Firefox */
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #999;
}

//PAGINATION

.pagination-row{
    @include rowItemsVCenter;
    justify-content: flex-end;
    margin-top: 1vh;
}

// .cdk-global-overlay-wrapper, .cdk-overlay-container {
  
//     z-index: 2000 !important;
// }

.dp-input-container{
    height: 100%;

    input{
        height: 100% !important;
        width: 100% !important;
        border: none;
        outline: none;
    }

    input:focus{
        outline: none  !important;
    }
}

.ng-select {
    .ng-select-container {
        color: #333;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
        align-items: center;
        min-height: calc(1.5em + 0.75rem + 2px) !important;
        height: calc(1.5em + 0.75rem + 2px) !important;
    }
}

.dp-open + div{
    top: calc(1.5em + .75rem + 2px) !important;
    left: 0 !important;
    width: 100%;
}

dp-day-time-calendar,
dp-day-calendar{
    width: 100%
}

dp-day-calendar.dp-material .dp-calendar-weekday,
dp-day-calendar .dp-calendar-day{
    width: calc(100%/7) !important;

}

dp-day-calendar.dp-material .dp-calendar-weekday{
    color: #17a2b8 !important;
    font-style: italic;
}

dp-day-calendar.dp-material .dp-calendar-day, dp-day-calendar.dp-material .dp-calendar-month{
    border-radius: 0 !important;
}

.no-data{
    @include perfectCenter;
    height: 100%;
    width: 100%;
}

@media screen and (max-height: 480px) and (orientation: landscape){
   html, body{
        font-size: 8px !important;
    };
}

@media screen and (max-width: 768px) and (orientation: portrait){
   html, body{
        font-size: 9px !important;
    };
}