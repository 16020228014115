/*FLEX MIXINS*/
@import "_variables.scss";

@mixin perfectCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin rowItemsVCenter {
    display: flex;
    align-items: center;
}

@mixin rowItemsHCenter {
    display: flex;
    justify-content: center;
}

@mixin colItemsHCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin colItemsVCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin justifyAround {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@mixin itemsVCenterRight{
    display: flex;
    align-items: center;
    justify-content: right;
}

@mixin justifyBetween{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*CURCULAR BTNS*/
@mixin round-button {
    background-color: $gray-300;
    margin-left: 1.85rem;
    height: 6.5vh;
    width: 6.5vh;
    border-radius: 50%;
}

/*SHADW MIXINS*/
@mixin shadow-1 {
    box-shadow: 0 .125rem .25rem $black-100 !important
}

@mixin shadow-2 {
    box-shadow: 0 0.5rem 1.5rem $black-100;
}