/*ALL VARIABLES WILL BE DECLARED HERE*/
$headerH: 8vh;
$viewH: 92vh;
$fontFamily:'Karla';
$fontFamilyBold:'Karla-Bold';
$primary: #ffffff;
$primaryText: rgba(0, 0, 0, 0.7);
$secondary: #123787;
$secondaryText: #FFFFFF;
$sidemenuBackground:#ffffff;
$sidemenuText:rgba(0,0,0,0.7);
$dark-blue: rgb(0, 71, 131);

/*COLOR VARIABLES*/
$body: #F7F7F7;
$copyright: rgba(250, 161, 161, 0.7);

/*FORM*/
$inputBorder: #80bdff;
$placeholder: #b1b1b1;


/*WHITE SHADES*/
$white: #FFF;

/*BLACK SHADES*/
$black: #000;
$black-100: rgba(0,0,0,.075);

/*GRAY SHADES*/
$gray-100: #F0F2F5;
$gray-200: #EFEFEF;
$gray-300: #E4E6EB;
$gray-400: #D3D9Db;
$gray-500: #E4E6EB;