/*CARD REALTED CSS*/

@import "_mixins.scss";

.custom-card{
    @include shadow-1;
    border-radius: 0.5rem !important;
    border: 0 !important;
    
}

.dashboard-icon{
    align-self: flex-end;
    margin-right: 0.5rem;
}

.device-count{
    align-self: center;
    font-family: "Karla-Bold";
    margin-top:auto;
    margin-bottom: auto;
}
